<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">FasTag Data</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Registration Number<span class="text-danger">*</span></label>
                            <input type="text" v-model="regnumber" class="form-control text-uppercase"
                                placeholder="Enter Registration Number" maxlength="10" :disabled="inputdisabled"
                                minlength="6" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12" v-if="!sendotpbtnhide">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '105px' }"
                                @click="checkFasTaginSystem(regnumber)" :disabled="regnumber.length < 6 || btnloader">
                                <span v-if="!btnloader">Get Details</span>
                                <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '378px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Registration Number</label>
                                            <div class="from-label-value text-uppercase">
                                                {{ votergovdata.rc_number ? votergovdata.rc_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Bank Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.bank_name ? votergovdata.bank_name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">FasTag Id</label>
                                            <div class="from-label-value text-uppercase">
                                                {{ votergovdata.tag_id ? votergovdata.tag_id : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Status</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.status ? votergovdata.status : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            regnumber: '',
            votergovdata: '',
            sendotpbtnhide: false,
            btnloader: false,
            mobiledetailshowflag: false,
            inputdisabled: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        checkFasTaginSystem(e) {
            this.disabledinput = true;
            this.btnloader = true;
            this.ApiService.getfasTagDetails({ rc_number: e }).then((data) => {
                if (data.status === true) {
                    this.btnloader = false;
                    this.votergovdata = data.data;
                    this.mobiledetailshowflag = true;
                    this.sendotpbtnhide = true;
                    this.inputdisabled = true;
                    this.clearbtnflag = true;
                } else {
                    this.btnloader = false;
                    this.inputdisabled = false;
                    this.mobiledetailshowflag = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });

        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.regnumber = '';
            this.votergovdata = '';
            this.sendotpbtnhide = false;
            this.btnloader = false;
            this.mobiledetailshowflag = false;
            this.inputdisabled = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 512px; */
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>